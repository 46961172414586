<template>
  <v-container fluid>
    <alarm-form />
  </v-container>
</template>

<script>
import AlarmForm from "./AlarmForm.vue";
export default {
  components: {
    AlarmForm,
  },

  data() {
    return {};
  },

  methods: {},
};
</script>